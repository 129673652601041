import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
import MaskedInput from 'antd-mask-input';

import options from './options';

export default () => {
  const { Item } = Form;
  return (
    <>
      <Row gutter={8}>
        <Col sm={24} md={8}>
          <Item
            label='Tipo'
            name='category'
          >
            <Select placeholder='Escolha o seu tipo de empresa' options={options} />
          </Item>
        </Col>
        <Col sm={24} md={16}>
          <Item
            hasFeedback
            name='cnpj'
            label='CNPJ'
            rules={[{ required: true, message: 'Entre com o cnpj' }]}
          >
            <MaskedInput placeholder='CNPJ' mask="11.111.111/1111-11" />
          </Item>
        </Col>
        <Col sm={24} md={16}>
          <Item
            hasFeedback
            name='name'
            label='Razão Social'
            rules={[{ required: true, message: 'Entre com a razão social' }]}
          >
            <Input placeholder='Razão social' />
          </Item>
        </Col>
        <Col sm={24} md={8}>
          <Item
            hasFeedback
            name='tradeName'
            label='Nome fantasia'
            rules={[{ required: true, message: 'Entre com o nome fantasia' }]}
          >
            <Input placeholder='Nome fantasia' />
          </Item>
        </Col>
      </Row>
    </>
  )
};

import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Select, Button, Input, Col, Row } from 'antd';

import MaskedInput from 'antd-mask-input';

import categories from './categories';
import streetTypes from './streetTypes';
import states from './states';

const { List, Item } = Form;

const rules = [{ required: true, message: 'Este campo é obrigatório' }];

export default () => {
  return (
    <List name='addresses'>
      {(fields, { add, remove }) => {
        return (
          <Item>
            {fields.map((field, index) => (
              <Row key={field.key} align='middle' >
                <Col span={22}>
                  <Row gutter={8}>
                    <Col xs={24} sm={12} md={4}>
                      <Item
                        label='Tipo'
                        name={[field.name, 'category']}
                        fieldKey={[field.fieldKey, 'category']}

                      >
                        <Select mode='multiple' options={categories} />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                      <Item
                        label='CEP'
                        name={[field.name, 'zipCode']}
                        fieldKey={[field.fieldKey, 'zipCode']}
                        rules={rules}
                      >
                        <MaskedInput placeholder='cep' mask='11111-111' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Item
                        label='Tipo do logradouro'
                        name={[field.name, 'streetType']}
                        fieldKey={[field.fieldKey, 'streetType']}
                        rules={rules}
                      >
                        <Select align='left' options={streetTypes} />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={10}>
                      <Item
                        label='Logradouro'
                        name={[field.name, 'street']}
                        fieldKey={[field.fieldKey, 'street']}
                        rules={rules}
                      >
                        <Input placeholder='Logradouro' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={6} md={4}>
                      <Item
                        label='Número'
                        name={[field.name, 'number']}
                        fieldKey={[field.fieldKey, 'number']}
                        rules={rules}
                      >
                        <Input placeholder='Número' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={18} md={10}>
                      <Item
                        label='Bairro'
                        name={[field.name, 'neighborhood']}
                        fieldKey={[field.fieldKey, 'neighborhood']}
                        rules={rules}
                      >
                        <Input placeholder='Bairro' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={5}>
                      <Item
                        label='Complemento'
                        name={[field.name, 'adjunct']}
                        fieldKey={[field.fieldKey, 'adjunct']}
                      >
                        <Input placeholder='Complemento' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={5}>
                      <Item
                        label='Referência'
                        name={[field.name, 'reference']}
                        fieldKey={[field.fieldKey, 'reference']}
                      >
                        <Input placeholder='Referência' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={16} md={18}>
                      <Item
                        label='Município'
                        name={[field.name, 'city']}
                        fieldKey={[field.fieldKey, 'city']}
                        rules={rules}
                      >
                        <Input placeholder='Município' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={8} md={6}>
                      <Item
                        label='UF'
                        name={[field.name, 'state']}
                        fieldKey={[field.fieldKey, 'state']}
                        rules={rules}
                      >
                        <Select align='left' options={states} />
                      </Item>
                    </Col>
                  </Row>

                </Col>
                <Col span={2} >
                  <Button
                    danger
                    style={{ margin: 5 }}
                    onClick={() => {
                      remove(field.name);
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </Col>
              </Row>

            ))}
            <Item>
              <Button
                type='dashed'
                onClick={() => {
                  add();
                }}
                style={{ width: '50%' }}
              >
                <PlusOutlined /> Adicionar endereço
              </Button>
            </Item>
          </Item>
        );
      }}
    </List >
  );
};

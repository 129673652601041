import React from 'react';
import { PageHeader } from 'antd';

import Table from 'components/Company/Table';
import CompanyDetails from '../Company/CompanyDetails';

import { useDispatch } from 'react-redux';
import { getUserInformation } from 'actions';

export default () => {
    const dispatch = useDispatch();
    dispatch(getUserInformation());
    return (
        <>
            <PageHeader
                title='Empresas'
                subTitle='Exibe uma lista de empresas'
                backIcon={false}
            />
            <Table />
            <CompanyDetails />
        </>
    );
};

import api from 'utils/api';
import { notify } from 'utils';
import { FETCH_COMPANIES, COMPANY_CREATE, COMPANY_DETAILS } from '../types';
import { toggleLoading, toggleDrawer } from '../index';

const errorNotification = (error) => {
  if (!error.response) {
    notify('error', 'Erro', 'Erro no servidor');
  } else {
    notify('error', 'Erro', error.response.data.message);
  }
};

export const create = (values) => async (dispatch) => {
  await dispatch(toggleLoading(true));
  try {
    const { data } = await api.post('/license/v1/company', values);
    await dispatch({ type: COMPANY_CREATE, payload: data });
    notify('success', 'Empresa cadastrada com sucesso');
  } catch (error) {
    errorNotification(error);
    // Adicionei o throw para não fazer o redirecionamento da pagina em caso de erro
    throw error;
  }
  dispatch(toggleLoading(false));
};

export const fetch = () => async (dispatch) => {
  await dispatch(toggleLoading(true));
  try {
    const { data } = await api.get('/license/v1/company');
    await dispatch({ type: FETCH_COMPANIES, payload: data });
  } catch (error) {
    errorNotification(error);
  }
  dispatch(toggleLoading(false));
};

export const details = (id) => async (dispatch) => {
  try {
    const { data } = await api.get(`/license/v1/company/${id}`);
    await dispatch({ type: COMPANY_DETAILS, payload: data });
    dispatch(toggleDrawer(true));
  } catch (error) {
    errorNotification(error);
  }
};

export const setActive = (params) => async (dispatch) => {
  await dispatch(toggleLoading(true));

  const { _id, active } = params;

  try {
    if (active) {
      await api.post(`/license/v1/company/${_id}/activation`);
    } else {
      await api.delete(`/license/v1/company/${_id}/activation`);
    }

    await dispatch(fetch());

    dispatch(toggleLoading(false));
  } catch (error) {
    errorNotification(error);
    dispatch(toggleLoading(false));
    throw error;
  }
};

export const renew = (values) => async (dispatch) => {
  await dispatch(toggleLoading(true));
  try {
    const { company: id, dueDate } = values;
    await api.put(`/license/v1/company/${id}/registration`, { dueDate });
    notify('success', 'Renovação concluída com sucesso');
  } catch (error) {
    errorNotification(error);
    dispatch(toggleLoading(false));
    throw error;
  }
};

export const update = (values) => async (dispatch) => {
  await dispatch(toggleLoading(true));
  try {
    const { _id } = values;
    const { data } = await api.put(`/license/v1/company/${_id}/`, { ...values });
    await dispatch({ type: COMPANY_DETAILS, payload: data });
    notify('success', 'Atualização realizada com sucesso');
    dispatch(toggleLoading(false));
  } catch (error) {
    errorNotification(error);
    dispatch(toggleLoading(false));
    throw error;
  }
}
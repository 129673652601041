import { USER } from 'actions/types';

const INITIAL_STATE = JSON.parse(localStorage.getItem(USER));

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER:
      return action.payload;

    default:
      return state;
  }
};

import React from 'react';
import { PageHeader } from 'antd';
import Renew from 'components/Company/Renew';

export default () => (
  <>
    <PageHeader
      title='Renovar a licença de utilização dos softwares clouds'
      backIcon={false}
    />
    <Renew />
  </>
);

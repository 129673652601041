import React from 'react';
import { Layout, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import LogOutButton from './LogOutButton';

export default () => {
  const { Header } = Layout;

  return (
    <Header style={{ background: '#fff' }}>

      <LogOutButton />
      <Button
        style={{ float: 'right', marginTop: 14, marginLeft: 10, marginRight: 10 }}
        onClick={() => console.log('clicked')}
        shape='circle-outline'
        type='link'
        icon={<SettingOutlined />}
      />
    </Header>
  );
};

import React from 'react';
import { Typography } from 'antd';

import { InfoCircleTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { details } from 'actions/company';

export default params => {
  const dispatch = useDispatch();
  const { data } = params;
  const { Text } = Typography;

  return (
    <div>
      <InfoCircleTwoTone
        onClick={async function() {
          await dispatch(details(data._id));
        }}
      />
      <Text> {data.name} </Text>
    </div>
  );
};

import React from 'react';
import {
  TagsOutlined,
  MessageOutlined,
  PieChartOutlined,
  StarOutlined,
  TeamOutlined,
  QuestionOutlined
} from '@ant-design/icons';

export default ({ id, children }) => {
  let icon;

  switch (id) {
    case 1:
      icon = <TagsOutlined />;
      break;

    case 2:
      icon = <MessageOutlined />;
      break;

    case 3:
      icon = <PieChartOutlined />;
      break;

    case 4:
      icon = <StarOutlined />;
      break;

    case 5:
      icon = <TeamOutlined />;
      break;

    default:
      icon = <QuestionOutlined />;
      break;
  }

  return (
    <span>
      {icon}
      <span>{children}</span>
    </span>
  );
};

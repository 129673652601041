import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Form from './Form';
import AuthWrapper from 'containers/AuthWrapper';

export default () => {
  const auth = useSelector(state => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (auth) {
      history.push('/');
    }
  }, [auth, history]);

  return (
    <AuthWrapper>
      <Form />
    </AuthWrapper>
  );
};

import { COMPANY_DETAILS } from 'actions/types';

export default (state = null, action) => {
    switch (action.type) {
        case COMPANY_DETAILS:
            return action.payload;

        default:
            return state;
    }
};

import React from 'react';
import { Layout } from 'antd';

export default ({ children }) => {
  const { Content } = Layout;

  return (
    <Content style={{ margin: 20 }}>
      <div style={{ padding: 25, background: '#fff', minHeight: '86vh' }}>{children}</div>
    </Content>
  );
};

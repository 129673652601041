export default [
    {
        value: 'CO',
        label: 'Comercial'
    },
    {
        value: 'FI',
        label: 'Financeiro'
    },
    {
        value: 'RH',
        label: 'Recursos humanos'
    }
];

import bg from 'assets/img/bg.webp';

export default {
  container: {
    minHeight: '100vh',
    textAlign: 'center',
    justifyContent: 'center',
    background: 'transparent'
  },
  content: {
    background: '#fff',
    padding: 20,
    margin: 20,
    borderRadius: 10,
    boxShadow:
      'rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 2px 4px -2px'
  },
  logo: {
    width: '30%',
    margin: 50
  },
  title: {
    fontWeight: '100',
    color: 'grey'
  },
  background: {
    background: `linear-gradient(
      rgba(114,98,169, 0.9),
      rgba(77,200,244, 0.7)
    ), url(${bg}) no-repeat center center fixed`,
    backgroundSize: 'cover'
  }
};

import {
  SIGN_IN,
  SIGN_OUT,
  TOKEN_KEY,
  TOGGLE_LOADING,
  TOGGLE_DRAWER,
} from './types';
import api from 'utils/api';
import { notify } from 'utils';

const errorNotification = (error) => {
  if (!error.response) {
    notify('error', 'Erro', 'Erro no servidor');
  } else {
    notify('error', 'Erro', error.response.data.message);
  }
};

export const getUserInformation = () => async (dispatch) => {
  try {
    await dispatch(toggleLoading(true));
    const { data } = await api.get('/auth/api/user/me');
    await dispatch(toggleLoading(false));
    return data;
  } catch (error) {
    dispatch(signOut());
    await dispatch(toggleLoading(false));
    return null;
  }
}
export const signIn = (values) => async (dispatch) => {
  const { email, password } = values;

  if (email && password) {
    await dispatch(toggleLoading(true));
    try {
      const { data } = await api.post('/auth/signin', { email, password });
      if (data.__v === 0) {
        notify('info', 'Primeiro acesso', data.message);
      } else {
        localStorage.setItem(TOKEN_KEY, data.token);
        await dispatch({ type: SIGN_IN });
      }
    } catch (error) {
      errorNotification(error);
    }
  }
  dispatch(toggleLoading(false));
};

export const signOut = () => {
  localStorage.clear();
  return { type: SIGN_OUT };
};

export const toggleLoading = (bool) => {
  return { type: TOGGLE_LOADING, payload: bool };
};

export const toggleDrawer = (bool) => {
  return { type: TOGGLE_DRAWER, payload: bool };
};

export const recoverPassword = (email) => async (dispatch) => {
  if (email) {
    await dispatch(toggleLoading(true));
    try {
      const { data } = await api.post('auth/recovery', { email });
      notify('success', 'E-mail enviado com sucesso', data.message);
    } catch (error) {
      errorNotification(error);
    }
  }
  dispatch(toggleLoading(false));
};

export const resetPassword = (values, token) => async (dispatch) => {
  const { password, confirm } = values;
  if (password && token) {
    await dispatch(toggleLoading(true));
    try {
      await api.post(`auth/reset/${token}`, {
        password,
        confirmPassword: confirm,
      });
      notify('success', 'Senha alterada com sucesso', 'Faça o login');
    } catch (error) {
      errorNotification(error);
    }
  }
  dispatch(toggleLoading(false));
};

export default [
    {
        value: 'CO',
        label: 'Cobrança'
    },
    {
        value: 'EN',
        label: 'Entrega'
    }
];

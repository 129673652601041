import React from 'react';
import { Layout, Row, Col, Divider, Typography } from 'antd';

import styles from './styles';
import logo from 'assets/img/logo.svg';

export default ({ children }) => {
  const { Content } = Layout;
  const { Text, Title } = Typography;

  return (
    <Layout style={styles.background}>
      <Row>
        <Col xs={24} sm={20} md={20} lg={10} xl={8}>
          <Layout style={styles.container}>
            <Content style={styles.content}>
              <img style={styles.logo} src={logo} alt='Surge' />
              <Title style={styles.title}>Gerador de licenças</Title>
              {children}
              <Divider>Suporte</Divider>
              <Text>(12) 3018-2438 / (12) 99744-8778</Text>
              <br />
              <a href='mailto:contato@cloudssistemas.com.br'>
                contato@cloudssistemas.com.br
              </a>
            </Content>
          </Layout>
        </Col>
      </Row>
    </Layout>
  );
};

import { notification } from 'antd';

export const notify = (type, title, message) => {
    notification[type]({
        message: title,
        description: message,
        placement: 'topLeft',
        duration: 3
    });
};

export const SOCKET_URL =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_SOCKET
        : 'http://localhost:5050';

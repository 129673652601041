export const TOKEN_KEY = 'TOKEN_KEY';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const USER = 'USER';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const COMPANY_DETAILS = 'COMPANY_DETAILS';
export const COMPANY_CREATE = 'COMPANY_CREATE';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export default [
  {
    value: 'CO',
    label: 'Comércio'
  },
  {
    value: 'PS',
    label: 'Prestação de serviços'
  }
];

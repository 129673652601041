import React from 'react';
import { Layout } from 'antd';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Panel from 'components/Panel';

export default ({ children }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <Sidebar />
    <Layout>
      <Header />
      <Panel>{children}</Panel>
    </Layout>
  </Layout>
);

import React from 'react';
import { Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';

import Icon from 'components/ItemIcon';
import links from './links';

export default () => {
  const { Item } = Menu;
  const { Sider } = Layout;

  const formattedLinks = links.map(item => (
    <Item key={item.id.toString()}>
      <Link to={item.url}>
        <Icon id={item.id}>{item.text}</Icon>
      </Link>
    </Item>
  ));

  return (
    <Sider collapsible breakpoint='lg'>
      <Menu mode='inline' theme='dark' defaultSelectedKeys={['1']}>
        <div style={{ margin: '40px 0', textAlign: 'center' }}>GERADOR DE LICENÇAS</div>
        {formattedLinks}
      </Menu>
    </Sider>
  );
};

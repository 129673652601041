import React from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, DatePicker, Button, Row, Col } from 'antd';

import { renew } from 'actions/company';

import Selector from '../Selector';

export default () => {
  const { Item } = Form;
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(state => state.loading);

  const dateFormat = 'DD/MM/YYYY';

  const onFinish = async values => {
    try {
      await dispatch(renew(values));
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed: ', errorInfo);
  };

  return (
    <Form
      name='company'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout='horizontal'
      initialValues={{ dueDate: moment(new Date(), dateFormat).add(30, 'days') }}
    >
      <Row>
        <Col xs={24} sm={16} md={18}>
          <Selector />
        </Col>
        <Col xs={24} sm={8} md={6}>
          <Item
            hasFeedback
            label='Próximo vencimento'
            name='dueDate'
            rules={[{ required: true, message: 'Informe o próximo vencimento' }]}
          >
            <DatePicker
              mode='date'
              format={dateFormat}
            />
          </Item>
        </Col>
      </Row>
      <Item>
        <Button
          loading={loading}
          type='primary'
          htmlType='submit'
          style={{ width: '100%' }}
        >
          Atualizar
        </Button>
      </Item>
    </Form>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export default ({ children, role, ...rest }) => {
  const auth = useSelector(state => state.auth);

  if (!auth) return <Redirect to='/login' />;

  return (
    <Route
      {...rest}
      render={() => {
        if (auth) {
          return children;
        } else {
          return <Redirect to={'/'} />;
        }
      }}
    />
  );
};

import React from 'react';

import ActionWrapper from 'containers/ActionWrapper';
import Form from './Form';

export default () => (
  <ActionWrapper title='Recuperar senha'>
    Entre a sua nova senha abaixo
    <Form />
  </ActionWrapper>
);

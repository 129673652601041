import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { resetPassword } from 'actions';

export default () => {
    const { Item } = Form;
    const { Password } = Input;
    const history = useHistory();
    const dispatch = useDispatch();
    const { token } = useParams();
    const loading = useSelector(state => state.loading);

    const _onFinish = async values => {
        try {
            await dispatch(resetPassword(values, token));
            history.push('/login');
        } catch (error) {
            console.log(error);
        }
    };

    const _onFinishFailed = errorInfo => {
        console.log('Failed: ', errorInfo);
    };

    return (
        <Form
            style={{ padding: 30 }}
            name='normal_login'
            initialValues={{ remember: false }}
            onFinish={_onFinish}
            onFinishFailed={_onFinishFailed}
        >
            <Item
                hasFeedback
                name='password'
                rules={[{ required: true, message: 'Entre a sua senha' }]}
            >
                <Password prefix={<LockOutlined />} placeholder='Entre sua nova senha' />
            </Item>
            <Item
                name='confirm'
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Por favor, confirme a sua senha'
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('As duas senhas são diferentes');
                        }
                    })
                ]}
            >
                <Password prefix={<LockOutlined />} placeholder='Confirme a entrada' />
            </Item>
            <Item>
                <Button
                    loading={loading}
                    type='primary'
                    htmlType='submit'
                    style={{ width: '100%' }}
                >
                    Resetar senha
                </Button>
                <Item>
                    <Button type='link' onClick={() => history.push('/login')}>
                        Voltar
                    </Button>
                </Item>
            </Item>
        </Form>
    );
};

import React from 'react';
import { PageHeader } from 'antd';

import Steps from 'components/Company/Steps';

export default () => (
  <>
    <PageHeader
      title='Empresa'
      subTitle='Cadastra empresas para utilização dos softwares da Clouds Sistemas'
      backIcon={false}
    />
    <Steps />
  </>
);

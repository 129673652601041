import Axios from 'axios';
import { TOKEN_KEY } from 'actions/types';

const api = Axios.create({
  baseURL: (process.env.NODE_ENV === 'production') ?
    'https://api.surgeonline.com.br' :
    `${process.env.REACT_APP_BASE_URL || 'https://api.surgeonline.com.br'}`
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
import { combineReducers } from 'redux';

import authReducer from './auhtentication/authReducer';
import userReducer from './auhtentication/userReducer';
import loadingReducer from './status/loadingReducer';
import errorReducer from './status/errorReducer';
import fetchCompaniesReducer from './companyMangement/fetchCompaniesReducer';
import companyDetailsReducer from './companyMangement/companyDetailsReducer';
import drawerReducer from './status/drawerReducer';

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    loading: loadingReducer,
    error: errorReducer,
    companies: fetchCompaniesReducer,
    company: companyDetailsReducer,
    drawer: drawerReducer
});

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { recoverPassword } from 'actions';

export default () => {
    const { Item } = Form;
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading);

    const _onFinish = async values => {
        try {
            await dispatch(recoverPassword(values.email));
        } catch (error) {
            console.log(error);
        }
    };

    const _onFinishFailed = errorInfo => {
        console.log('Failed: ', errorInfo);
    };

    return (
        <Form
            style={{ padding: 30 }}
            name='normal_login'
            initialValues={{ remember: false }}
            onFinish={_onFinish}
            onFinishFailed={_onFinishFailed}
        >
            <Item
                hasFeedback
                name='email'
                rules={[{ required: true, message: 'Entre o seu e-mail' }]}
            >
                <Input prefix={<UserOutlined />} placeholder='E-mail' />
            </Item>
            <Item>
                <Button
                    loading={loading}
                    type='primary'
                    htmlType='submit'
                    style={{ width: '100%' }}
                >
                    Recuperar senha
                </Button>
                <Item>
                    <Button type='link' onClick={() => history.goBack()}>
                        Voltar
                    </Button>
                </Item>
            </Item>
        </Form>
    );
};

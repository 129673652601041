import React from 'react';
import ActiveSwitch from 'components/ActiveSwicth';
import DetailLink from 'components/Company/DetailLink';
import SearchFilter from 'components/Company/TableFilters/SearchFilter';

import { useDispatch } from 'react-redux';
import { setActive } from 'actions/company';

export default () => {
  const dispatch = useDispatch();

  const formatDate = date =>
    date ? new Date(date).toLocaleDateString('pt-BR', { dateStyle: 'medium' }) : null;

  const handleSwitchClick = async function (data) {
    let { _id, active } = data;
    active = !active;
    setActive({ _id, active })
    dispatch(setActive({ _id, active }));
  }

  const handleSorter = (a, b, sortOrder) => {
    switch (sortOrder) {
      case 'ascend':
        return (a > b ? 1 : -1)
      case 'descend':
        return (a < b ? -1 : 1)
      default:
        return -1
    }
  }

  return [
    {
      title: 'Ativo',
      render: (value, record) => {
        return (<ActiveSwitch data={record} onClick={() => handleSwitchClick(value)} />);
      },
      filters: [
        {
          text: 'Ativo',
          value: true,
        },
        {
          text: 'Inativo',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.active === value;
      },
    },
    {
      title: 'Razão social',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        return <DetailLink data={record} />;
      },
      ...SearchFilter({ field: 'name' }),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, sortOrder) => handleSorter(a.name, b.name, sortOrder)
    },
    {
      title: 'Nome fantasia',
      dataIndex: 'tradeName',
      key: 'tradeName',
      ...SearchFilter({ field: 'tradeName' }),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, sortOrder) => handleSorter(a.tradeName, b.tradeName, sortOrder)
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      ...SearchFilter({ field: 'cnpj' }),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, sortOrder) => handleSorter(a.cnpj, b.cnpj, sortOrder)
    },
    {
      title: 'Ultima renovação',
      dataIndex: 'lastRenewal',
      key: 'lastRenewal',
      render: formatDate,
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, sortOrder) => handleSorter(a.lastRenewal, b.lastRenewal, sortOrder)
    },
    {
      title: 'Próximo vencimento',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: formatDate,
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b, sortOrder) => handleSorter(a.dueDate, b.dueDate, sortOrder)
    }
  ]
}

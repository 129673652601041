export default [
  { value: 'ACAMPAMENTO', label: 'ACAMPAMENTO' },
  { value: 'ACESSO', label: 'ACESSO' },
  { value: 'AEROPORTO', label: 'AEROPORTO' },
  { value: 'ALAMEDA', label: 'ALAMEDA' },
  { value: 'ANEL VIÁRIO', label: 'ANEL VIÁRIO' },
  { value: 'AREA', label: 'ÁREA' },
  { value: 'AREA ESPECIAL', label: 'ÁREA ESPECIAL' },
  { value: 'AVENIDA', label: 'AVENIDA' },
  { value: 'CAMPO', label: 'CAMPO' },
  { value: 'COMUNIDADE', label: 'COMUNIDADE' },
  { value: 'CONDOMINIO', label: 'CONDOMINIO' },
  { value: 'CONJUNTO', label: 'CONJUNTO' },
  { value: 'CONTORNO', label: 'CONTORNO' },
  { value: 'CORREGO', label: 'CÓRREGO' },
  { value: 'DESVIO', label: 'DESVIO' },
  { value: 'ESCADA', label: 'ESCADA' },
  { value: 'ESTADIO', label: 'ESTÁDIO' },
  { value: 'ESTACAO', label: 'ESTAÇÃO' },
  { value: 'ESTRADA', label: 'ESTRADA' },
  { value: 'FAZENDA', label: 'FAZENDA' },
  { value: 'PRACA', label: 'PRAÇA' },
  { value: 'PROLONGAMENTO', label: 'PROLONGAMENTO' },
  { value: 'QUADRA', label: 'QUADRA' },
  { value: 'RODOVIA', label: 'RODOVIA' },
  { value: 'RUA', label: 'RUA' },
  { value: 'TRAVESSA', label: 'TRAVESSA' },
  { value: 'TREVO', label: 'TREVO' },
  { value: 'VIA', label: 'VIA' }
]





























import React from 'react';
import { Form, Input, Select, Button, Col, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import Categories from './categories'

export default () => {
  // const rules = [{ required: true, message: 'Este campo é obrigatório' }];
  const { Item, List } = Form;


  return (
    <List name='contacts'>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map(field => (
              <Row key={field.key} align='middle'>
                <Col span={22}>
                  <Row gutter={8}>
                    <Col xs={24} sm={12} md={8}>
                      <Item
                        label='Tipo'
                        name={[field.name, 'category']}
                        fieldKey={[field.fieldKey, 'category']}
                      >
                        <Select
                          mode='multiple'
                          options={Categories}
                        />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={16}>
                      <Item
                        label='E-mail'
                        name={[field.name, 'email']}
                        fieldKey={[field.fieldKey, 'email']}
                      >
                        <Input placeholder='E-mail' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Item
                        label='Nome'
                        name={[field.name, 'name']}
                        fieldKey={[field.fieldKey, 'name']}
                      >
                        <Input placeholder='Nome' />
                      </Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Item
                        label='Telefone'
                        name={[field.name, 'phone']}
                        fieldKey={[field.fieldKey, 'phone']}
                      >
                        <Input placeholder='Telefone' />
                      </Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={2}>
                  <Button
                    danger
                    style={{ margin: 5 }}
                    onClick={() => {
                      remove(field.name);
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </Col>

              </Row>
            ))
            }

            <Item>
              <Button
                type='dashed'
                onClick={() => {
                  add();
                }}
                style={{ width: '50%' }}
              >
                <PlusOutlined /> Adicionar contato
              </Button>
            </Item>
          </>
        );
      }}
    </List >
  );
};

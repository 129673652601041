import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { signIn } from 'actions';

export default () => {
    const { Item } = Form;
    const { Password } = Input;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading);

    const onFinish = async values => {
        try {
            await dispatch(signIn(values));
        } catch (error) {
            console.log(error);
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed: ', errorInfo);
    };

    return (
        <Form
            style={{ padding: 30 }}
            name='normal_login'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <label htmlFor='normal_login_email'>Entre o seu e-mail</label>
            <label htmlFor='normal_login_password'> e sua senha abaixo</label>
            <Item
                hasFeedback
                name='email'
                rules={[{ required: true, message: 'Entre o seu e-mail' }]}
            >
                <Input prefix={<UserOutlined />} placeholder='E-mail' />
            </Item>
            <Item
                hasFeedback
                name='password'
                rules={[{ required: true, message: 'Entre a sua senha' }]}
            >
                <Password prefix={<LockOutlined />} placeholder='Senha' />
            </Item>

            <Item>
                <Item name='remember' valuePropName='checked' noStyle>
                    <Checkbox>Lembrar de mim</Checkbox>
                </Item>

                <Link to='/recuperar-senha' style={{ float: 'right' }}>
                    Esqueci a minha senha
                </Link>
            </Item>

            <Item>
                <Button
                    loading={loading}
                    type='primary'
                    htmlType='submit'
                    style={{ width: '100%' }}
                >
                    Entrar
                </Button>
            </Item>
        </Form>
    );
};

import React, { useState } from 'react';
import { Steps, Layout, Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { create } from 'actions/company';

import steps from './steps';
import CustomForm from 'components/CustomForm';

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { Step } = Steps;
  const { Content } = Layout;

  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();

  const next = () => {
    setCurrent(current + 1);
    setFormData({ ...formData, ...form.getFieldsValue() });
  };

  const prev = () => {
    setCurrent(current - 1);
    setFormData({ ...formData, ...form.getFieldsValue() });
  }

  const handleChange = current => setCurrent(current);

  const handleFinish = async values => {
    try {
      setFormData({ ...formData, ...form.getFieldsValue() });
      dispatch(create({ ...formData, ...form.getFieldsValue() }));
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Content style={{ padding: 16 }}>
      <Steps current={current} onChange={handleChange}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Content
        style={{
          marginTop: 16,
          border: '1px dashed #e9e9e9',
          borderRadius: '2px',
          backgroundColor: '#fafafafa',
          minHeight: '200px',
          textAlign: 'center',
          padding: 24
        }}
      >
        <CustomForm form={form} onFinish={handleFinish} layout={'vertical'} >{steps[current].content}</CustomForm>
      </Content>
      <div style={{ margin: 20 }}>
        {current < steps.length - 1 && (
          <Button type='primary' onClick={next}>
            Próximo
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type='primary' onClick={() => form.submit()}>
            Enviar
          </Button>
        )}
        {current > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={prev}>
            Voltar
          </Button>
        )}
      </div>
    </Content>
  );
};

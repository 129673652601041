import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Login from 'pages/Auth/Login';
import RecoverPassword from 'pages/Auth/RecoverPassword';
import ResetPassword from 'pages/Auth/ResetPassword';

import AdminRoutes from 'Routes/AdminRoutes';

export default () => (
  <Switch>
    <Route path='/login'>
      <Login />
    </Route>
    <Route path='/recuperar-senha'>
      <RecoverPassword />
    </Route>
    <Route path='/password/reset/:token'>
      <ResetPassword />
    </Route>
    <PrivateRoute path='/'>
      <AdminRoutes />
    </PrivateRoute>
  </Switch>
);

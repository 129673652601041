import React from 'react';
import { Form } from 'antd';

export default props => {

  const onFinishFailed = errorInfo => {
    console.log('Failed: ', errorInfo);
  };

  return (
    <Form
      form={props.form}
      name='company'
      onFinish={props.onFinish}
      onFinishFailed={onFinishFailed}
      layout={props.layout}
    >
      {props.children}
    </Form>
  );
};

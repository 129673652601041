import React from 'react'
import { Row } from 'antd'

const DetailRow = (props) => (
    <Row style={{ 'justifyContent': 'space-between', ...props.style }}>
        { props.children}
    </Row >
)


export default DetailRow;
import React from 'react';
import { Switch } from 'antd';


export default params => {

  const { data, size, onClick } = params;

  return (
    <Switch
      size={size || "medium"}
      checked={data.active || data.available}
      onClick={onClick}
    />
  );
};

export default [
    {
        id: 1,
        url: '/',
        text: 'Empresas'
    },
    {
        id: 2,
        url: '/cadastrar',
        text: 'Cadastrar empresa'
    },
    {
        id: 3,
        url: '/renovar',
        text: 'Atualizar licença'
    }
];

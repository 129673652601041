import React from 'react';
import { Modal, Button } from 'antd';
import { LogoutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { signOut } from 'actions';

export default () => {
  const { confirm } = Modal;
  const dispatch = useDispatch();

  const confirmSignOut = () => {
    confirm({
      title: 'Você tem certeza de que deseja sair do sistema?',
      icon: <ExclamationCircleOutlined />,
      content: 'Clique confirmar para sair',
      okText: 'Sair',
      okType: 'danger',
      onOk() {
        dispatch(signOut());
      },
      cancelText: 'Cancelar'
    });
  };

  return (
    <Button
      style={{
        float: 'right',
        marginTop: 14
      }}
      onClick={confirmSignOut}
      type='primary'
      icon={<LogoutOutlined />}
    >
      Sair
    </Button>
  );
};

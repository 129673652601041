import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Form } from 'antd';
import { fetch } from 'actions/company';

export default () => {
  const { Item } = Form;
  const dispatch = useDispatch();

  const companies = useSelector(data => {
    return data.companies.map(company => {
      return {
        label: `${company.cnpj} - ${company.name}`,
        value: company._id
      };
    });
  });

  const loading = useSelector(data => data.loading);

  const handleFilter = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
  }

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  return (
    <Item label='Empresa' name='company'>
      <Select
        showSearch
        options={companies}
        loading={loading}
        filterOption={handleFilter} />
    </Item>
  );
};

import React from 'react';
import { Drawer, Typography, Divider, Col, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import ActiveTag from 'components/Company/ActiveTag';
import ActiveSwicth from 'components/ActiveSwicth';

import { toggleDrawer } from 'actions';

import DetailRow from 'components/Company/DetailRow';
import ContactCategories from 'components/Company/Steps/Contact/categories';
import AddressCategories from 'components/Company/Steps/Address/categories';

import { update } from 'actions/company';

export default () => {
  const company = useSelector(state => state.company);
  const drawer = useSelector(state => state.drawer);

  const dispatch = useDispatch();

  const { Text } = Typography;

  const _onClose = () => {
    if (!company.modules) {
      dispatch(update(company));
    }
    dispatch(toggleDrawer(false));
  };

  const renderCategories = (categories) => (
    <>
      <Text style={{ marginRight: '5px' }}>Tipo:</Text>
      {categories.map(category => (
        <Tag key={category.value}>{category.label}</Tag>
      ))}
    </>
  )

  const getContactCategories = (value) => {
    const categories = ContactCategories.filter(category => value.includes(category.value));
    return renderCategories(categories);
  }

  const getAddressCategories = (value) => {
    const categories = AddressCategories.filter(category => value.includes(category.value));
    return renderCategories(categories);
  }

  const handleSwitchClick = async (value) => {
    value.available = !value.available;
    const modules = company.modules.map(elevePackge => {
      if (elevePackge.packageName === value.packageName) {
        return value
      } else {
        return elevePackge
      }
    });
    const companyData = {
      ...company,
      modules
    };
    dispatch(update(companyData));
  }

  if (!company) return null;

  return (
    <Drawer
      width={640}
      title={`Empresa #${company.name}`}
      placement='right'
      visible={drawer}
      onClose={_onClose}
    >
      <ActiveTag data={company} />
      <DetailRow>
        <Text >{`Id: ${company._id}`}</Text>
      </DetailRow>
      <DetailRow>
        <Text >{`CNPJ: ${company.cnpj}`}</Text>
        <Text >{`Razão Social: ${company.name}`}</Text>
      </DetailRow>
      <DetailRow >
        <Text >{`Nome Fantasia: ${company.tradeName}`}</Text>
        <Text >{`Data de fundação: ${company.foundationDate || ''}`}</Text>
      </DetailRow>
      <DetailRow >
        <Text >{`Inscrição Estadual: ${company.stateRegistration || ''}`}</Text>
        <Text >{`Inscrição Municipal: ${company.cityRegistration || ''}`}</Text>
      </DetailRow>
      <DetailRow >
        <Text >{`Serial: ${company.serial}`}</Text>
        <Text >{`Número de Usuários: ${company.numberOfUsers || 1}`}</Text>
      </DetailRow>
      <Divider>Endereços</Divider>
      {company.addresses && company.addresses.map(address => (
        <Col key={new Date()}>
          <DetailRow style={{ justifyContent: 'left' }}>
            {getAddressCategories(address.category)}
          </DetailRow>
          <DetailRow>
            <Text>{`CEP: ${address.zipCode}`}</Text>
            <Text>{`Logradouro: ${address.streetType} ${address.street}`}</Text>
            <Text>{`Número: ${address.number}`}</Text>
          </DetailRow>
          <DetailRow>
            <Text>{`Bairro: ${address.neighborhood}`}</Text>
            <Text>{`Complemento: ${address.adjunct || ''}`}</Text>
            <Text>{`Referência: ${address.reference || ''}`}</Text>
          </DetailRow>
          <DetailRow>
            <Text>{`Município: ${address.city}`}</Text>
            <Text>{`UF: ${address.state}`}</Text>
          </DetailRow>
        </Col>
      ))}
      <Divider>Contatos</Divider>
      {company.contacts && company.contacts.map(contact => (
        <Col key={new Date()}>
          <DetailRow style={{ justifyContent: 'left' }}>
            {getContactCategories(contact.category)}
          </DetailRow>
          <DetailRow>
            <Text>{`Telefone: ${contact.phone || ''}`}</Text>
            <Text>{`E-mail: ${contact.email || ''}`}</Text>
          </DetailRow>
        </Col>
      ))}
      <Divider>Modulos</Divider>
      {company.modules && company.modules.map(elevePackge => (
        <Col key={elevePackge.packageName} >
          <DetailRow >
            <Text>{elevePackge.name}</Text>
            <ActiveSwicth data={elevePackge} size={"small"} onClick={() => handleSwitchClick(elevePackge)} />
          </DetailRow>
        </Col>
      ))}

    </Drawer >
  );
};

import React from 'react';
import { Tag } from 'antd';

export default params => {
  const { data } = params;

  let color;
  let text;

  switch (data.active || data.available) {
    case true:
      color = 'success';
      text = 'Ativo';
      break;

    case false:
      color = 'error';
      text = 'Inativo';
      break;

    default:
      color = 'processing';
      text = 'Processando';
      break;
  }

  return (
    <>
      Situação Cadastral: <Tag color={color}>{text}</Tag>
    </>
  )
};

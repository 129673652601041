import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchFilter = (props) => {
  const { field } = props;
  const [searchText, setSearchText] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState([]);
  const [searchInput, setSearchInput] = useState([]);

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText([]);
    setSearchedColumn([]);
  };

  const handleSearch = (selectedKeys, confirm, field) => {
    confirm();
    setSearchText([...searchText, selectedKeys[0]]);
    setSearchedColumn([...searchedColumn, field]);
  };

  return {
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            if (node && !searchInput.find((elem) => elem.field === field)) {
              setSearchInput([
                ...searchInput,
                ...[{ field: field, input: node }],
              ]);
            }
          }}
          placeholder='Filtro'
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, field)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Filtrar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Limpar
        </Button>
      </div>
    ),
    onFilter: (value, record) => {
      return record[field].toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      try {
        const { input } = searchInput.find((elem) => elem.field === field);
        if (visible && input) {
          setTimeout(() => input.select());
        }
      } catch (err) {}
    },
  };
};

export default SearchFilter;

import React from 'react';

import Form from './Form';
import AuthWrapper from 'containers/AuthWrapper';

export default () => {
  return (
    <AuthWrapper>
      <p>
        Entre o seu e-mail no formulário abaixo e enviaremos um link para recuperação de
        senha
      </p>
      <Form />
    </AuthWrapper>
  );
};

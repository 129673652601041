import React from 'react';

import Main from './Main';
import Addresses from './Address';
import Contact from './Contact';

export default [
  {
    title: 'Dados Principais',
    content: <Main />
  },
  {
    title: 'Endereços',
    content: <Addresses />
  },
  {
    title: 'Contato',
    content: <Contact />
  }
];

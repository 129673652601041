import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';

import columns from './columns';
import { fetch } from 'actions/company';

export default () => {
  const dispatch = useDispatch();
  const companies = useSelector(data => data.companies);
  const loading = useSelector(data => data.loading);

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  return (
    <Table rowKey='_id' columns={columns()} dataSource={companies} loading={loading} />
  );
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminWrapper from 'containers/AdminWrapper';

import Home from 'pages/Admin/Home';
import Company from 'pages/Admin/Company';
import Renew from 'pages/Admin/Renew';

export default () => (
    <AdminWrapper>
        <Switch>
            <Route exact path='/'>
                <Home />
            </Route>
            <Route exact path='/cadastrar'>
                <Company />
            </Route>
            <Route exact path='/renovar'>
                <Renew />
            </Route>
        </Switch>
    </AdminWrapper>
);
